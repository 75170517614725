<template>
  <v-container class="down-top-padding" fluid>
    <base-breadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"></base-breadcrumb>
    <base-card heading="Relatórios da Aplicação">
      <v-row v-if="true">
        <v-col cols="12" md="12">
          <v-card class="mx-auto">
            <v-card-text>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="gray"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>As métricas são atualizadas a cada {{
                    stats.cache_interval
                  }}. Última atualização: {{ stats.cached_at }}</span>
              </v-tooltip>
              <div v-if="statsFetch">
                <ListCardReportInfoVue :data="infoCardData" />
              </div>
              <div v-else>
                <v-row class="d-flex justify-space-between py-4">
                <v-col cols="12" md="3" class="pt-0" v-for="i in 3" :key="i">
                  <v-skeleton-loader
                    type="card-heading, list-item"
                    v-bind="{
                    boilerplate: true,
                    elevation: 2
                    }"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
              </div>
              <v-divider></v-divider>
              <v-row class="py-6">
                <div class="d-flex justify-center" style="width: 100%">
                  <v-btn-toggle v-model="tableType" mandatory>
                    <v-btn v-for="i in types" :key="i.id" :value="i.id" outlined style="height: 56px">
                      {{ i.name }}
                    </v-btn>
                    <v-btn
                      outlined
                      style="height: 56px"
                      value="1"
                    >
                      Acertos por questão
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-row>
              <v-row v-if="tableType === '1'" align="center" class="mx-2">
                <v-checkbox v-model="showQuestionsTotalNumber">
                  <template v-slot:label>
                    <span class="mr-3">Visualizar números totais</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="gray" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                      </template>
                      <span>Alterna entre porcentagem e números totais</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-row>
              <template>
                <v-stepper v-model="tableType">
                  <v-stepper-items>
                    <v-stepper-content step="0">
                      <v-container class="my-2">
                        <v-row>
                          <v-col class="position-relative" cols="12">
                            <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              background-color="transparent"
                              dense
                              hide-details
                              label="Pesquisar alunos"
                              outlined
                              @keydown="e => keyObserver(e)"
                              @click:append="getPeople()"
                            ></v-text-field>
                            <div style="position: absolute;color: #A3A3A3;font-size: 0.7rem;">Aperte ENTER para pesquisar</div>
                          </v-col>
                        </v-row>
                      </v-container>
                      <datatable-list-people
                        v-if="studentsFetch"
                        :displayActionItems="displayActionItems"
                        :headers="studentsHeaders"
                        :items="studentsItems"
                        :loading="!studentsFetch"
                        :pagination="pagination"
                        :showSelect="showSelect"
                        :tableUser="true"
                        :text="text"
                        @update="pagination = { ...pagination, ...$event}"
                        @updateSort="updateSort"
                      ></datatable-list-people>
                      <template v-else>
                        <v-skeleton-loader
                          v-for="i in 5"
                          :key="i"
                          type="table-heading"
                          v-bind="{
                          boilerplate: true,
                          elevation: 2
                          }"
                        >
                        </v-skeleton-loader>
                      </template>
                    </v-stepper-content>
                    <v-stepper-content step="1">
                      <datatable-list
                        v-if="questionsFetch"
                        :displayActionItems="displayActionItems"
                        :headers="questionsHeaders"
                        :items="questionsItems"
                        :loading="!questionsFetch"
                        :pagination="pagination"
                        :showSelect="showSelect"
                        :sortBy="sortBy"
                        :tableUser="false"
                        :text="text"
                        @update="pagination = { ...pagination, ...$event}"
                      ></datatable-list>
                      <template v-else>
                        <v-skeleton-loader
                          v-for="i in 5"
                          :key="i"
                          type="table-heading"
                          v-bind="{
                          boilerplate: true,
                          elevation: 2
                          }"
                        >
                        </v-skeleton-loader>
                      </template>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import ListCardReportInfoVue from '../../components/list/ListCardReportInfo.vue'
import { getStatusColor, getStatusName } from '@/utils/status'
import {
  isSuperAdmin,
  getSchoolIds,
  getCourseIds,
  hasOrganizationLevel
} from '@/utils/helpers'

export default {
  components: { ListCardReportInfoVue },
  data: () => ({
    id: '',
    finalGrade: 0,
    page: {
      title: 'Relatórios'
    },
    param: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Aplicações de Avaliações',
        disabled: false,
        to: '/applications',
        exact: true
      }
    ],
    tableType: 1,
    types: [
      { name: 'Nota dos alunos', id: 0 }
    ],
    search: '',
    displayActionItems: false,
    showSelect: false,
    sortBy: '',
    sortDesc: '',
    studentsHeaders: [
      { text: 'Aluno', align: 'start', value: 'name' },
      { text: 'Nota', align: 'center', value: 'grade' },
      { text: 'Reabrir', value: 'reopen', align: 'start' },
      { text: 'Corrigir', value: 'correct', align: 'start' }
    ],
    studentsItems: [],
    studentsFetch: false,
    isSearching: false,
    questionsHeaders: [
      { text: 'Questão', align: 'start', value: 'question.text' },
      { text: 'Habilidades', align: 'center', value: 'question.competency_matrices[0][0].code' },
      { text: '% de respostas', align: 'center', value: 'stats.answered_percentage' },
      { text: '% de não respondidas', align: 'center', value: 'stats.blank_percentage' },
      { text: '% de acerto', align: 'center', value: 'stats.hit_percentage' },
      { text: '% de erro', align: 'center', value: 'stats.error_percentage' }
    ],
    questionsItems: [],
    questionsFetch: false,
    text: {
      searchLabel: 'Pesquisar',
      emptyLabel: 'Nenhum dado encontrado',
      addLabel: '',
      addUrl: '',
      importLabel: '',
      importUrl: ''
    },
    showQuestionsTotalNumber: false,
    statsFetch: false,
    stats: {},
    infoCardData: [],
    pagination: {
      page: 1,
      prev_page_url: false,
      next_page_url: false
    },
    courses: [],
    course_ids: [],
    school_ids: []
  }),
  watch: {
    sortBy (newV, oldV) {
      if (newV === oldV) return
      clearTimeout(this._timerId)
      this._timerId = setTimeout(async () => {
        this.getPeople()
      }, 1500)
    },
    sortDesc (newV, oldV) {
      if (newV === oldV) return
      clearTimeout(this._timerId)
      this._timerId = setTimeout(async () => {
        this.getPeople()
      }, 1500)
    },
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    },
    showQuestionsTotalNumber () {
      if (!this.showQuestionsTotalNumber) {
        this.questionsHeaders = [
          { text: 'Questão', align: 'start', value: 'question.text' },
          { text: 'Habilidades', align: 'center', value: 'question.competency_matrices[0][0].code' },
          { text: '% de respostas', align: 'center', value: 'stats.answered_percentage' },
          { text: '% de não respondidas', align: 'center', value: 'stats.blank_percentage' },
          { text: '% de acerto', align: 'center', value: 'stats.hit_percentage' },
          { text: '% de erro', align: 'center', value: 'stats.error_percentage' }
        ]
      } else {
        this.questionsHeaders = [
          { text: 'Questão', align: 'start', value: 'question.text' },
          { text: 'Habilidades', align: 'center', value: 'question.competency_matrices[0][0].code' },
          { text: 'Nota total', align: 'center', value: 'stats.total_grade' },
          { text: 'Não respondidas', align: 'center', value: 'stats.not_answered' },
          { text: 'Acertos somados', align: 'center', value: 'stats.answers_grade' },
          { text: 'Erros somados', align: 'center', value: 'stats.errors_grade' }
        ]
      }
    }
  },

  computed: {
    ...mapState(['sidebarDrawer'])
  },

  methods: {
    getCourseIds,
    getSchoolIds,
    isSuperAdmin,
    hasOrganizationLevel,
    ...mapMutations({
      setSidebarDrawer: 'setSidebarDrawer'
    }),
    getStatusColor,
    getStatusName,
    buildPagination () {
      return `?page=${this.pagination.page}`
    },
    formatSeconds (sec) {
      if (!sec) return 0
      const date = new Date(0)
      date.setSeconds(sec) // specify value for SECONDS here
      return date.toISOString().substr(11, 8)
    },
    formatDate (d) {
      if (!d) return 'Não iniciado'
      return format(parseISO(d), 'dd/MM/yyyy HH:mm')
    },
    striphtml (text) {
      if (!text) return ''
      return text
        .replace(/<[^>]*>?/gm, '')
        .replace('\\(', '')
        .replace('\\)', '')
        .replace(/&nbsp;/g, '')
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach(value => {
        if (value) {
          this.finalGrade = (value.grade || 0).toFixed(2)
          const obj = {
            ...value,
            avatar: value.student_avatar,
            name: value.student_name,
            email: value.student_email,
            id: value.student_id,
            courses: value.course_ids,
            reopen: {
              enabled: value.status !== 'NOT_STARTED'
            },
            status: {
              name: this.getStatusName(value.status),
              color: this.getStatusColor(value.status),
              value: value.status
            },
            started_at: this.formatDate(value.started_at || ''),
            ended_at: this.formatDate(value.ended_at || ''),
            grade: (value.grade || 0).toFixed(2),
            time_spent: this.formatSeconds(value.time_spent),
            url: (value.status === 'NOT_STARTED') ? null : `/exam/${this.$route.params.exam}/application/${this.$route.params.application}/resolution/${value.id}`,
            isSelected: false,
            student_id_for_reopen: value.id,
            correct: {}
          }
          arr.push(obj)
        }
      })
      return arr
    },
    async loadSchools () {
      await this.$axios.get(`/organizations/${this.id}/schools?page=1&per_page=10`)
        .then(response => {
          const data = response.data
          data.items.map(obj => {
            this.school_ids.push(obj.metadata.id)
          })
        })
        .finally(() => {
          this.getPeople()
        })
    },
    getPeople () {
      if (this.isSearching) return

      this.studentsFetch = false
      this.isSearching = true

      const payload = {
        ...(this.search ? { search: this.search } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getCourseIds().length ? { course_ids: this.getCourseIds() } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getSchoolIds().length ? { school_ids: this.getSchoolIds() } : {})
      }
      this.$axios
        .post(
            `/organizations/${this.id}` +
            `/lists/exams-applications/${this.$route.params.application}` +
            `/students${this.buildPagination()}`,
            payload
        )
        .then(response => {
          this.studentsItems = this.formatPeoples(response.data.items)
          const pagination = response.data.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page
          }
        })
        .finally(() => {
          this.studentsFetch = true
          this.isSearching = false
        })
    },
    async getStats () {
      this.statsFetch = false
      try {
        await this.$axios
          .post(
            `/organizations/${this.id}/lists/exams-applications/${this.$route.params.application}/stats`)
          .then(response => {
            this.stats = {
              ...response.data,
              average_grade: response.data.average.toFixed(2),
              lowest_grade: response.data.lowest_grade.toFixed(2),
              highest_grade: response.data.highest_grade.toFixed(2),
              median: response.data.median.toFixed(2),
              stand_deviation: response.data.stand_deviation.toFixed(2),
              cached_at: format(parseISO(response.data.cached_at), 'dd/MM/yyyy HH:mm')
            }
          })
      } catch (error) {
      } finally {
        this.statsFetch = true
        this.infoCardData = [
          { title: 'Nota média', text: this.stats.average_grade ? this.stats.average_grade : 0, color: 'info' },
          { title: 'Menor nota', text: this.stats.lowest_grade ? this.stats.lowest_grade : 0, color: 'indigo darken-3' },
          { title: 'Maior nota', text: this.stats.highest_grade ? this.stats.highest_grade : 0, color: 'indigo' },
          { title: 'Mediana', text: this.stats.median ? this.stats.median : 0, color: 'teal' },
          { title: 'Desvio padrão', text: this.stats.stand_deviation ? this.stats.stand_deviation : 0, color: 'red lighten-1' },
          { title: 'Tempo médio de resolução', text: this.formatSeconds(this.stats.average_time), color: 'light-green darken-1' }
        ]
      }
    },
    selectCourse (e) {
      this.course_ids = e
      this.getPeople()
    },
    loadApplication () {
      this.$axios
        .get(
          `/organizations/${this.id}/exams/${this.$route.params.exam}/applications/${this.$route.params.application}`
        ).then(
          res => (this.courses = res.data.data.courses_group.map(value => {
            return {
              value: value.id,
              text: value.name
            }
          }))
        )
    },
    async getQuestions () {
      this.questionsFetch = false
      try {
        await this.$axios
          .get(
            `/organizations/${this.id}/lists/exams-applications/${this.$route.params.application}/answers/stats`)
          .then(response => {
            Object.values(response.data).forEach((item) => {
              this.questionsItems.push({
                question: {
                  ...item.question,
                  text: this.striphtml(item.question.main_text) !== ''
                    ? this.striphtml(item.question.main_text.substr(0, 100) + '...').trim()
                    : '(Imagem) ' + this.striphtml(item.question.wordings[0][0].wording.substr(0, 100) + '...').trim()
                },
                stats: {
                  total_grade: item.stats.total_grade,
                  answers_grade: item.stats.answers_grade,
                  errors_grade: item.stats.errors_grade,
                  not_answered: item.stats.not_answered,
                  hit_percentage: (item.stats.hit_percentage * 100).toFixed(2) + '%',
                  error_percentage: (item.stats.error_percentage * 100).toFixed(2) + '%',
                  blank_percentage: (item.stats.blank_percentage * 100).toFixed(2) + '%',
                  answered_percentage: (item.stats.answered_percentage * 100).toFixed(2) + '%'
                }
              })
            })
          })
      } catch (error) {
      } finally {
        this.questionsFetch = true
      }
    },
    updateSort (item, value) {
      if (value === undefined) {
        this.sortBy = ''
        this.sortDesc = ''
      } else {
        if (item === 'column') {
          this.sortBy = value
        }
        if (item === 'desc') {
          this.sortDesc = value
        }
      }
    },
    keyObserver (e) {
      if (e.which === 13) {
        this.getPeople()
      }
    }
  },
  beforeDestroy () {
    this.setSidebarDrawer(true)
  },

  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.loadSchools()
    this.getPeople()
    await this.getStats()
    await this.getQuestions()
    this.loadApplication()

    this.breadcrumbs.push(
      {
        text: 'Aplicação',
        to: `/exam/application/view/${this.$route.params.exam}/${this.$route.params.application}`,
        disabled: false
      },
      {
        text: 'Relatório da Aplicação',
        disabled: true
      }
    )

    this.setSidebarDrawer(false)
  }
}
</script>
